<template>
  <section
    :class="{
      'mb-2 mr-3 bg-white rounded-md shadow flex flex-col h-full': true,
      'h-full': !!detail,
    }"
  >
    <div class="flex w-full pl-3 md-max:pl-0 md-max:block">
      <div
        class="block w-3/5 px-6 pt-10 pb-15 md-max:px-8 md-max:w-full md-max:h-48 fake-grid-item-border-bottom"
      >
        <div class="flex pt-4 md-max:pt-6">
          <div class="mr-5">
            <img
              src="@/assets/icon/rental-duration.svg"
              class="w-12 h-12"
              alt="Rental Duration"
            />
          </div>
          <div class="flex flex-col">
            <div class="mb-2 text-sm font-bold">
              {{
                $t(
                  'components.vehicleRentalsManagement.details.title.rentalDuration'
                )
              }}
            </div>
            <div class="text-sm text-color-sub">{{ startTime }}</div>
            <div class="text-sm text-color-sub">{{ endTime }}</div>
          </div>
        </div>

        <div class="flex pt-4">
          <div class="mr-5">
            <img
              src="@/assets/icon/delivery-address.svg"
              class="w-12 h-12"
              alt="Rental Duration"
            />
          </div>
          <div class="flex flex-col">
            <div class="mb-2 text-sm font-bold">
              {{
                $t(
                  'components.vehicleRentalsManagement.details.title.delivery&ReturnAddress'
                )
              }}
            </div>
            <div class="text-sm text-color-sub">{{ address }}</div>
          </div>
        </div>
      </div>
      <div class="grid w-2/5 grid-cols-2 md-max:w-full md-max:h-48">
        <template v-for="(item, index) in categories">
          <div
            class="items-center grid-item place-content-center "
            :class="getCategoryClass(item.title)"
            :key="index"
          >
            <trip-muti-icon :item="item" />
          </div>
        </template>
      </div>
    </div>

    <div class="w-full flex-grow" v-if="!detail">
      &nbsp;
    </div>

    <div
      class="w-full flex-grow relative "
      style="min-height:400px;"
      v-if="detail"
    >
      <gmap-map
        ref="map"
        :zoom="zoom"
        :center="center"
        map-type-id="terrain"
        style="width: 100%; height: 100%;"
        @click="onTabMap"
      >
        <gmap-marker
          :position="getLatLng(detail.src_coord)"
          :icon="{
            url: require(`@/assets/img/start_place.png`),
            scaledSize: { width: 63, height: 73 },
          }"
        >
        </gmap-marker>
        <gmap-marker
          v-if="!detail.drop_off_location && lastTripStatus === 'O'"
          :position="getLatLng(detail.bike.current_loc_coord)"
          :icon="{
            url: require(`@/assets/icon/status/vehicle-active.svg`),
            scaledSize: { width: 40, height: 40 },
          }"
        >
        </gmap-marker>
        <div v-if="getTripPath().length">
          <gmap-marker
            v-for="item in getTripPath()"
            :key="item.created_at"
            :position="getLatLng(item.location)"
            :icon="{
              url: require(`@/assets/img/icons/map_marker@3x.png`),
              scaledSize: { width: 30, height: 30 },
            }"
          >
          </gmap-marker>
        </div>
        <gmap-marker
          v-if="this.detail.dest_coord"
          :position="this.getLatLng(this.detail.dest_coord)"
          :icon="{
            url: require(`@/assets/img/end_place.png`),
            scaledSize: { width: 63, height: 73 },
          }"
        >
        </gmap-marker>
        <template
          v-if="getRentStationAreas.length > 0 && rentStationMarkerOpen"
        >
          <gmap-marker
            :key="index"
            v-for="(area, index) in getRentStationAreas"
            :position="getLatLng(area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/Rent_R_icon.png`),
              scaledSize: { width: 45, height: 55 },
            }"
            @click="toggleMarker(area, -1, 'Rent')"
          />
        </template>
        <template v-if="getRestrictedAreas.length > 0 && restrictedMarkerOpen">
          <gmap-marker
            :key="index"
            v-for="(area, index) in getRestrictedAreas"
            :position="getLatLng(area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/R_icon.png`),
              scaledSize: { width: 45, height: 55 },
            }"
            @click="toggleMarker(area, -1, 'R')"
          />
        </template>
        <template v-if="getSlowSpeedAreas.length > 0 && slowSpeedMarkerOpen">
          <gmap-marker
            :key="index"
            v-for="(area, index) in getSlowSpeedAreas"
            :position="getLatLng(area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/S_icon.png`),
              scaledSize: { width: 45, height: 45 },
            }"
            @click="toggleMarker(area, -1, 'S')"
          />
        </template>
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div v-html="infoContent"></div>
        </gmap-info-window>
      </gmap-map>
      <div class="area-info" v-if="getMarkerControlCounter > 0">
        <div class="flex flex-wrap items-center justify-between px-4 area-body">
          <button
            v-if="getRentStationAreas.length > 0"
            @click="rentStationMarkerOpen = !rentStationMarkerOpen"
            class="flex items-center justify-between mx-1"
          >
            <img
              src="@/assets/img/areas_pin/Rent_R_icon.png"
              width="20px"
              class="mr-2"
            />
            {{ rentStationMarkerOpen ? 'Hide' : 'Show' }}
          </button>

          <button
            v-if="getRestrictedAreas.length > 0"
            @click="restrictedMarkerOpen = !restrictedMarkerOpen"
            class="flex items-center justify-between mx-1"
          >
            <img
              src="@/assets/img/areas_pin/R_icon.png"
              width="20px"
              class="mr-2"
            />
            {{ restrictedMarkerOpen ? 'Hide' : 'Show' }}
          </button>

          <button
            v-if="getSlowSpeedAreas.length > 0"
            @click="slowSpeedMarkerOpen = !slowSpeedMarkerOpen"
            class="flex items-center justify-between mx-1"
          >
            <img
              src="@/assets/img/areas_pin/S_icon.png"
              width="20px"
              class="mr-2"
            />
            {{ slowSpeedMarkerOpen ? 'Hide' : 'Show' }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TripMutiIcon from './TripMultiIcon.vue'
import { gmapApi } from 'vue2-google-maps'

import ReconnectingWebsocket from 'reconnectingwebsocket'
import { SocketConfig } from '@/config/SocketConfig'

import { getFormattedDateTime } from '@/utils/datetime'
import { EventBus } from '@/utils/EventBus'

export default {
  components: {
    TripMutiIcon,
  },
  props: ['detail', 'rentalDetail', 'onTripStatusText', 'onTripStatusVariant'],
  computed: {
    google: gmapApi,
    lastTripStatus() {
      return this.$store.getters['lastTrip/getStatus']
    },
    startTime() {
      return getFormattedDateTime(this.rentalDetail.start_time)
    },
    endTime() {
      return getFormattedDateTime(this.rentalDetail.end_time)
    },
    address() {
      return this.rentalDetail
        ? this.rentalDetail.delivery_address || '--'
        : '--'
    },
    getRentStationAreas() {
      return this.rentalDetail?.rent_areas_in_geofence
    },
    getRestrictedAreas() {
      return this.rentalDetail?.restricted_areas_in_geofence
    },
    getSlowSpeedAreas() {
      return this.rentalDetail?.slowzone_areas_in_geofence
    },
    getMarkerControlCounter() {
      let counter = 0
      if (this.getRentStationAreas.length > 0) {
        counter++
      }
      if (this.getRestrictedAreas.length > 0) {
        counter++
      }
      if (this.getSlowSpeedAreas.length > 0) {
        counter++
      }
      console.log('getMarkerControlCounter', counter)
      return counter
    },
  },
  beforeDestroy() {
    this.removeListener()
  },
  mounted() {
    this.$nextTick(() => {
      this.initDraw()
    })
    this.categories.forEach((category) => {
      if (!this.detail) return '--'

      console.log('categ = ', category)
      if (category.id == 'bike') {
        category.title =
          this.detail && this.detail.bike
            ? this.detail.bike.bike_category == 'S'
              ? 'Bike'
              : this.detail.bike.bike_category == 'P'
              ? 'Scooter'
              : this.detail.bike.bike_category == 'E'
              ? 'EBike'
              : this.detail.bike.bike_category == 'C'
              ? 'Coco'
              : this.detail.bike.bike_category == 'K'
              ? 'Kenota'
              : this.detail.bike.bike_category == 'PP'
              ? 'Scooter Pro'
              : '--'
            : '--'
      } else if (category.id == 'reserve') {
        category.title = `${parseInt(
          this.detail.start_power_level
        )}% -> ${parseInt(this.detail.end_power_level)}%`
      } else if (category.id == 'rating') {
        category.title = this.rentalDetail.trip.rating
        category.subTitle =
          this.rentalDetail.trip.rating === '--' ? 'Not Rated' : 'Rated'

        category.icon_class =
          this.rentalDetail.trip.rating === 'Good'
            ? 'fas fa-thumbs-up category-icon'
            : this.rentalDetail.trip.rating === 'Bad'
            ? 'fas fa-thumbs-down category-icon'
            : 'fas fa-star category-icon'

        category.color =
          this.rentalDetail.trip.rating === 'Good'
            ? '#3ebc30'
            : this.rentalDetail.trip.rating === 'Bad'
            ? '#d90a20'
            : '#ffed00'
      } else if (category.id == 'travel') {
        const distance = parseFloat(this.detail.distance).toFixed(1)
        var toSet = '--'
        if (distance > 0) {
          toSet = parseFloat(distance / 1000).toFixed(2) + ' KM'
        }
        category.title = toSet
      }
    })

    console.log('detail = ', this.detail)

    if (this.detail) {
      let pos = this.getLatLng(this.detail.src_coord)
      this.center = {
        lat: pos.lat,
        lng: pos.lng,
      }
    }

    this.zoom = 17
    // setTimeout(() => {
    //   this.getDirection()
    // }, 1000)
    setTimeout(() => {
      this.boundingExtenders = this.getMapBoundExtenders()
    }, 1500)

    if (
      this.detail &&
      !this.detail.drop_off_location &&
      this.lastTripStatus === 'O'
    ) {
      this.startListener()
    }
  },
  data() {
    return {
      travelMode: 'DRIVING',
      rentStationMarkerOpen: false,
      restrictedMarkerOpen: false,
      slowSpeedMarkerOpen: false,
      infoWinOpen: false,

      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -70,
        },
      },
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      boundingExtenders: [],
      sockListener: null,
      onTripStatusData: {
        text: 'On Rent',
        variant: 'purple',
      },
      categories: [
        {
          id: 'bike',
          icon_class: 'fas fa-bicycle category-icon',
          title: 'BIKE',
          subTitle: this.$t(
            'components.vehicleRentalsManagement.details.map.vehicle'
          ),
          color: '#37b4ff',
        },
        {
          id: 'rating',
          icon_class: 'category-icon',
          title: '--',
          subTitle: 'Rated',
          color: '#00b52c',
        },
        {
          id: 'travel',
          icon_class: 'fas fa-road category-icon',
          title: '--',
          subTitle: this.$t(
            'components.vehicleRentalsManagement.details.map.travelled'
          ),
          color: '#00b52c',
        },
        {
          id: 'reserve',
          icon_class: 'fas fa-battery-quarter category-icon',
          title: '1%',
          subTitle: this.$t(
            'components.vehicleRentalsManagement.details.map.battery'
          ),
          color: '#9b37ff',
        },
      ],
      center: {
        lat: 10,
        lng: 10,
      },
      map: null,
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      rentStationDrawingOptions: {
        fillColor: 'rgba(88, 28, 135, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#581C87',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 2,
      },
      restrictedDrawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 3,
      },
      slowSpeedDrawingOptions: {
        fillColor: 'rgba(156, 163, 175, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#9CA3AF',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 4,
      },
      origin: null,
      destination: null,
      canceled: false,
      zoom: 12,
      showInfo: false,
      infoWIndowContext: {
        position: {
          lat: 44.2899,
          lng: 11.8774,
        },
      },
      vehicles: [
        {
          id: 'S',
          name: 'Scooter',
        },
        {
          id: 'P',
          name: 'Bike',
        },
        {
          id: 'E',
          name: 'EBike',
        },
      ],
    }
  },
  watch: {
    boundingExtenders(val) {
      this.resetMapBounds(val)
    },
    onTripStatusData: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('onTripStatusData updated', val)
      },
    },
  },
  methods: {
    getCategoryClass(type) {
      if (type === 'Good') {
        return 'bg-green-100'
      } else if (type === 'Bad') {
        return 'bg-red-100'
      } else {
        return ''
      }
    },
    initDraw() {
      const self = this
      setTimeout(() => {
        if (!self.$refs.map) {
          this.initDraw()
          return
        }
        self.$refs.map.$mapPromise.then((map) => {
          this.map = map
          this.drawServiceArea()
          if (this.rentalDetail?.rent_areas_in_geofence.length > 0) {
            this.drawRentStationArea()
          }
          if (this.rentalDetail?.restricted_areas_in_geofence.length > 0) {
            this.drawRestrictedArea()
          }
          if (this.rentalDetail?.slowzone_areas_in_geofence.length > 0) {
            this.drawSlowSpeedArea()
          }
        })
      }, 1000)
    },
    drawServiceArea() {
      const overlay = this.parseToOverlay(this.rentalDetail?.geofence.coords)

      // Get bounds
      const bounds = new this.google.maps.LatLngBounds()
      for (const path of overlay) {
        bounds.extend(path)
      }

      // draw polygon
      const parsedPolygon = new this.google.maps.Polygon({
        paths: overlay,
        ...this.serviceDrawingOptions,
      })
      parsedPolygon.setMap(this.map)
    },
    drawRentStationArea() {
      this.rentalDetail?.rent_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.rentStationDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    drawRestrictedArea() {
      this.rentalDetail?.restricted_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.restrictedDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    drawSlowSpeedArea() {
      this.rentalDetail?.slowzone_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.slowSpeedDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    toggleMarker(area, idx, areaType) {
      this.infoWinOpen = true
      this.infoWindowPos = this.getLatLng(area.center_coords)
      if (this.currentMidx == idx) {
        // this.infoWinOpen = !this.infoWinOpen
      } else {
        // this.infoWinOpen = true
        this.currentMidx = idx
      }
      this.infoContent = this.getInfoWindowContent(area, areaType)
    },
    getInfoWindowContent: function(item, areaType) {
      //work from here

      return `
        <div class="card">
          <div class="card-content">
            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Area Name:</span>
              <span class="ml-2 card-text ">
              <a href="${this.getAreaTypeLink(areaType)}${
        item.id
      }" class="font-semibold text-blue-600 capitalize " target="_blank"> ${
        item.name == undefined ? ' ' : item.name
      } </a>
              </span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Address:</span>
              <span class="ml-2 card-text">${item.address}</span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Latitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lat
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Longitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lng
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Active Status:</span>
              <span class="ml-2 card-text font-semibold">${
                item.is_active
                  ? '<span class="text-green-600">Yes<span>'
                  : '<span class="text-gray-600">No<span>'
              }</span>
            </div>
             </div>
        </div>
      `
    },
    getAreaTypeLink(type) {
      if (type === 'Rent') {
        return `/geofence/rent-station-areas/`
      }
      if (type === 'R') {
        return `/geofence/restricted-areas/`
      }
      if (type === 'S') {
        return `/geofence/slow-zone-areas/`
      }
    },
    getTripPath() {
      if (!this.detail) return []

      if (this.detail.traversed_path.length) {
        return this.detail.traversed_path
      } else if (this.detail.on_ride_trip_path.length) {
        return this.detail.on_ride_trip_path
      } else {
        return []
      }
    },
    getMapBoundExtenders() {
      if (!this.detail) return []

      var extenders = [this.getLatLng(this.detail.src_coord)]
      if (!this.detail.dest_coord && this.lastTripStatus === 'O') {
        extenders.push(this.getLatLng(this.detail.bike.current_loc_coord))
      }
      if (this.detail.dest_coord && this.lastTripStatus !== 'O') {
        extenders.push(this.getLatLng(this.detail.dest_coord))
      }
      return extenders
    },
    resetMapBounds(bounds) {
      if (!this.$refs.map) return
      var updatedBounds = new this.google.maps.LatLngBounds()
      for (const bound of bounds) {
        updatedBounds.extend(bound)
      }
      this.$refs.map.fitBounds(updatedBounds)
    },
    toggleInfoWindow(context) {
      this.infoWIndowContext = context
      this.showInfo = true
    },
    infoClicked(context) {
      console.log(context)
    },
    // getDirection() {
    //   var directionsService = new this.google.maps.DirectionsService()
    //   var directionsDisplay = new this.google.maps.DirectionsRenderer({
    //     suppressMarkers: true,
    //   })

    //   console.log('dire = ', this.$refs.map.$mapObject)
    //   directionsDisplay.setMap(this.$refs.map.$mapObject)

    //   var vm = this

    //   directionsService.route(
    //     {
    //       origin: this.getLatLng(this.detail.src_coord),
    //       destination: this.getLatLng(this.detail.dest_coord),
    //       travelMode: 'DRIVING',
    //     },
    //     function(response, status) {
    //       if (status === 'OK') {
    //         directionsDisplay.setDirections(response)
    //         var leg = response.routes[0].legs[0]
    //         vm.makeMarker(leg.start_location, 'start', 'title')
    //         vm.makeMarker(leg.end_location, 'end', 'title')
    //       } else {
    //         console.log('response = ', response)
    //         vm.makeMarker(response.request.origin.location, 'start', 'title')
    //         //window.alert('Directions request failed due to ' + status);
    //       }
    //     }
    //   )
    // },
    // makeMarker(position, icon, title) {
    //   new this.google.maps.Marker({
    //     position: position,
    //     map: this.$refs.map.$mapObject,
    //     icon:
    //       icon == 'start'
    //         ? {
    //             url: require(`@/assets/img/start_place.png`),
    //             scaledSize: { width: 63, height: 73 },
    //           }
    //         : {
    //             url: require(`@/assets/img/end_place.png`),
    //             scaledSize: { width: 63, height: 73 },
    //           },
    //     title: title,
    //   })
    // },
    onTabMap(event) {
      console.log(event.latLng.lat(), event.latLng.lng())
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = parseFloat(position.split(',')[0])
      let lng = parseFloat(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeUpdates +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = function() {
        console.log('Opened Connection...')
      }
      this.sockListener.onclose = function() {
        console.log('Closed Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket to refresh stream'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_updates') return

      var data = payload.data
      // console.log('Recieved Payload...', data)

      let bikeId = data['id']
      if (!bikeId) return
      if (this.detail.bike.id !== bikeId) return

      console.log(
        `Incoming data for bike ${bikeId} : location --> ${data['location']}`
      )

      if (data.status_flags) {
        console.log(
          `Incoming data for bike ${bikeId} : on ride status --> ${data.status_flags.is_on_ride}`
        )
        if (!data.status_flags.is_on_ride) {
          this.removeListener()
          EventBus.$emit('fetchTripDetail', this.detail.id)
          return
        }
      }
      console.log(
        `Incoming data for bike ${bikeId} : on ride status --> ${data.status_flags.is_on_ride}`
      )
      if (this.detail.bike.current_loc_coord === data['location']) {
        console.log(
          `Skipping updates : Has same data --> ${this.detail.bike.current_loc_coord}`
        )
      } else {
        const prevData = this.detail.bike.current_loc_coord
        this.detail.bike.current_loc_coord = data['location']
        console.log(
          `Updated data for bike ${bikeId} | location : ${prevData} --> ${data['location']}`
        )
      }
      this.boundingExtenders = this.getMapBoundExtenders()
    },
  },
}
</script>

<style lang="scss">
.arrow {
  height: 80px;
  width: 1px;
  /* background: #dfdfdf; */
  margin-left: 14px;
  border-right: 2px dotted #dfdfdf;
}
.arrow i {
  color: #dfdfdf;
  position: absolute;
  left: -4px;
  bottom: -10px;
}

.fake-grid-item-border-bottom {
  border-bottom: 2px solid #f6f6f6 !important;
}

@media (max-width: 990px) {
  .arrow {
    height: 60px;
  }
}
.area-info {
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  .area-body {
    height: 100%;
    margin: auto;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 0px #757575bf;
  }
}
</style>
